<template>
  <div>
    <h6 style="padding-top: 15px" class="section">{{ eventSeriesModule.title }}</h6>
    <span class="badge bg-warning">{{ eventSeriesModule.priority }}</span>
    <div class="row" style="padding-top: 2px">
      <div class="col-auto">
        <div class="dropdown">
          <button class="btn btn-primary-transparent border border-dark text-red dropdown-toggle" type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown" aria-expanded="false">
            {{ selectedOption.beginLocation }} - {{ selectedOption.beginDateString }} - {{ selectedOption.lessons }} UE
            ({{
              getOptionSuffix(selectedOption.lessons, selectedOption.dates.length)
            }})
          </button>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
            <li v-for="moduleDate in eventSeriesModule.eventSeriesModules" v-bind:key="moduleDate.id">
              <a class="dropdown-item text-red" @click="handleOptionClick(moduleDate)">
                {{ moduleDate.beginLocation }} - {{ moduleDate.beginDateString }} -
                {{ moduleDate.lessons }} UE ({{
                  getOptionSuffix(moduleDate.lessons, moduleDate.dates.length)
                }})
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-auto">
        <button class="btn btn-primary-transparent text-red border border-dark" @click="openModal">Modul
          buchen
        </button>
        <module-booking-dialog-component :seriesBookingId="seriesBookingId" :module="eventSeriesModule"
                                         :option="moduleDetails" v-if="isModalOpen" @close="closeModal"/>
      </div>
    </div>
    <hr/>
  </div>
</template>
<script>
import ModuleBookingDialogComponent from "@/components/ModuleBookingDialogComponent.vue";

export default {
  inject: ['sematoClient'],
  components: {
    ModuleBookingDialogComponent
  },
  data() {
    return {
      selectedOption: this.eventSeriesModule.eventSeriesModules[0],
      isModalOpen: false,
      moduleDetails: null
    };
  },
  props: ['eventSeriesModule', 'seriesBookingId'],
  mounted() {
    // Set the first item from eventSeriesModules as the default selection
    this.selectedOption = this.eventSeriesModule.eventSeriesModules[0];
  },
  methods: {
    handleOptionClick(option) {
      this.selectedOption = option;
    },
    getOptionSuffix(lessons, numEventDays) {
      if (lessons === 4 && numEventDays === 1) {
        return "kompakt";
      } else if ((lessons > 4 && lessons <= 8) && numEventDays === 1) {
        return "ganztägig";
      } else if (numEventDays > 1) {
        return numEventDays + "-tägig";
      }
    },
    loadModule(moduleId) {
      this.sematoClient.get(`event/${moduleId}`)
          .then(response => {
            this.moduleDetails = response.data
            this.isModalOpen = true;
          })
    },
    openModal() {
      this.loadModule(this.selectedOption.id)
    },
    closeModal() {
      this.isModalOpen = false;
    }
  }
}
</script>

<style scoped>
.btn-primary-transparent {
  background-color: transparent;
  border-color: transparent;
  color: #E30613; /* Red text color */
}

.text-red {
  color: #E30613; /* Red text color */
}

.dropdown-menu {
  border: 1px solid #000; /* Black border */
}
</style>