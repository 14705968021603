<template>
  <nav>
    <div v-if="embedded" class="header">
      <div class="header-left">
        <img class="header-logo"
             src="../assets/logo.png"/>
      </div>
      <div class="header-middle"></div>
      <div class="header-right">
        <div class="hamburger" onclick="this.classList.toggle('open');">
          <div class="hamburger-menu">
            <div class="menu-entry" onclick="window.open('https://lernwelt.paediko-akademie.de','_blank')">Lernwelt
            </div>
            <div class="menu-entry" v-on:click="logout()">Ausloggen</div>
          </div>
        </div>
        <button class="button button-active">
          <span class="button-text">Meine Veranstaltungen</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-door"
               viewBox="0 0 16 16">
            <path
                d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z"/>
          </svg>
        </button>
        <button v-if="this.$store.getters.user.premium" class="button"
                onclick="window.open('https://lernwelt.paediko-akademie.de','_blank')">
          <span class="button-text">Lernwelt</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               class="bi bi-arrow-right-circle" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
          </svg>
        </button>
        <button class="button" v-on:click="logout()">
          <span class="button-text">Ausloggen</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person"
               viewBox="0 0 16 16">
            <path
                d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"/>
          </svg>
        </button>
      </div>
    </div>
  </nav>
</template>

<script>
import OIDCClient from '../client/oidcClient'

const oidcClient = new OIDCClient()

export default {
  name: 'campusHeader',

  methods: {
    logout() {
      oidcClient.signOut()
    }
  },
  computed: {
    embedded(){
      return this.$route.query.embedded != "true";
    }
  }
}
</script>
<style scoped>

@media only screen and (max-device-width: 799px) {
  .header .header-middle {
    display: none;
  }

  .header .header-left .header-logo {
    display: flex;
  }

  .header .header-right .button-text {
    font-size: 10px;
  }

  .header .header-right button {
    float: right;
    margin-top: 4%;
  }

  .button .bi {
    display: none;
  }

  .header-right {
    float: right;
  }

  .button {
    display: none;
  }

  .button-active {
    display: none;
  }

  .hamburger {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }

  .hamburger::before,
  .hamburger::after {
    content: "";
    display: block;
    position: absolute;
    width: 30px;
    height: 3px;
    background-color: #000;
  }

  .hamburger::before {
    top: 10px;
  }

  .hamburger::after {
    top: 20px;
  }

  .hamburger::before, .hamburger::after {
    transition: transform 0.3s;
  }

  .hamburger.open .hamburger-menu {
    display: block;
  }

  .hamburger-menu .menu-entry {
    display: block;
    padding: 10px 20px;
    margin-top: 15px;
    background-color: rgba(117, 166, 165, 0.5);
    color: white;
    text-align: center;
    text-decoration: none;
    border-radius: 4px;
    font-size: 25px;
  }

  .hamburger-menu {
    padding-top: 25%;
    opacity:    1;
    background: white;
    width:      100%;
    height:     100%;
    z-index:    10;
    top:        0;
    left:       0;
    position:   fixed;
  }

}

.hamburger-menu {
  display: none;
}

.header {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  --box-shadow-color: rgb(0 0 0 / 20%);
  --button-background-color: rgba(117, 166, 165, 0.5);
  height: 11%;
  background-color: #FFFFFF;
  border-color: #FFFFFF;
}

.header-left {
  width: 10%;
  display: inline-block;
  padding-left: 25px;
  padding-right: 25px;
}

.header-left .header-logo {
  height: auto;
  width: 70px;
  padding-top: 6px;
  padding-bottom: 6px;
  border-top-style: none;
  border-right-style: none;
  border-bottom-style: none;
  border-left-style: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: 0px 0px 0px 0px rgb(3 169 244);
  padding-right: 6px;
  padding-left: 6px;
}

.header-middle {
  width: 50%;
  display: inline-block;
}

.header-right {
  display: inline-block;
  width: 35%;
  position: relative;
  margin-left: 50px;
}

.button {
  box-shadow: 0px 3px 7px var(--box-shadow-color);
  margin-right: 15px;
  border-color: transparent;
  background-color: var(--button-background-color);
  color: #FFFFFF;
  border-radius: 10px;
}

.button .button-text {
  font-size: 20px;
  font-weight: normal;
  text-transform: none;
}

.button .bi {
  margin-left: 10px;
  margin-right: 10px;
  transform: scale(1.25);
}

.button-active {
  --box-shadow-color: #E30613;
  --button-background-color: #E30613;
  opacity: 0.7;
  cursor: default;
}

</style>